@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

html {
  scrollbar-gutter: stable;
  scroll-behavior: smooth;
}

.benefits-grid {
  display: grid;
  gap: 20px;
  margin-top: 20px;
  min-height: 0;
  /* Prevents grid blowout */
}

.benefit-card {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  height: fit-content;
}

.benefit-image {
  flex: 0 0 300px;
  position: relative;
}

.image-placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 200px;
  position: relative;
}

.benefit-description {
  will-change: max-height;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.benefit-description.expanded {
  max-height: 1000px !important;
}

.benefit-content {
  flex: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.benefit-link {
  display: inline-block;
  margin-top: auto;
  padding: 8px 16px;
  background: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.benefit-link:hover {
  background: #0056b3;
}

/* Optional: Add a loading spinner */
.image-placeholder::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.legacy-warning {
  background-color: #fff3cd;
  color: #856404;
  padding: 5px 10px;
  border-radius: 4px;
  margin-top: 5px;
  font-size: 0.9em;
}

.benefit-list li {
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.benefits-grid {
  display: grid;
  gap: 20px;
  margin-top: 20px;
}

.benefit-card {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.benefit-image {
  flex: 0 0 300px;
}

.benefit-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.benefit-content {
  flex: 1;
  padding: 15px;
}

.benefit-description {
  max-height: 100px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.benefit-description.expanded {
  max-height: none;
}

.benefit-link {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

.user-form label::after {
  content: none;
}

.img-preview {
  max-width: 300px;
  height: auto;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.quill-editor {
  margin-bottom: 15px;
}

.description-preview {
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.user-form .main-title {
  font-family: 'League Gothic', sans-serif;
  color: #FFA726;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5em;
  letter-spacing: 0.5px;
}

.user-form .subtitle {
  font-family: 'Roboto', sans-serif;
  color: #1976D2;
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.4em;
  font-weight: 500;
  line-height: 1.4;
}

.user-form .completion-notice {
  background-color: #E3F2FD;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.user-form .completion-notice p {
  color: #1976D2;
  margin: 0;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.user-form .input-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.user-form .helper-text {
  color: #666;
  font-size: 0.85em;
  margin-top: 5px;
  display: block;
  font-family: 'Roboto', sans-serif;
}


/* Styles for the user form container */
.user-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Styles for the top buttons container */
.user-form .top-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Styles for the main heading */
.user-form h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8em;
  color: #333;
}

/* Styles for form groups */
.user-form .form-group {
  margin-bottom: 15px;
}

/* Styles for form controls (input fields) */
.user-form .form-control {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Styles for buttons */
.user-form .btn {
  margin-top: 10px;
}

/* Styles for the button group container */
.user-form .button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Styles for the quiz list */
.user-form .quiz-list {
  list-style-type: none;
  padding: 0;
}

/* Styles for list items in the quiz list */
.user-form .quiz-list li {
  margin-bottom: 10px;
}

/* Styles for link buttons */
.user-form .btn-link {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

/* Styles for the eligibility results container */
.user-form .eligibility-results {
  margin-top: 20px;
}

/* Styles for the eligibility results heading */
.user-form .eligibility-results h3 {
  text-align: center;
}

/* Styles for the eligibility results list */
.user-form .eligibility-results ul {
  list-style-type: none;
  padding: 0;
}

/* Styles for list items in the eligibility results list */
.user-form .eligibility-results ul li {
  padding: 10px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

/* Styles for the benefits dropdown list */
.user-form .benefits-dropdown ul {
  list-style-type: none;
  padding: 0;
}

/* Styles for list items in the benefits dropdown list */
.user-form .benefits-dropdown ul li {
  padding: 10px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

/* Styles for the divider */
.user-form .divider {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #ccc;
}

/* Styles for alert messages */
.alert {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
}

/* Styles for success alert messages */
.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

/* Styles for danger alert messages */
.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.user-form .main-title {
  font-family: 'League Gothic', sans-serif;
  color: #FFA726;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5em;
  letter-spacing: 0.5px;
}

.user-form .subtitle {
  font-family: 'Roboto', sans-serif;
  color: #1976D2;
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.4em;
  font-weight: 500;
  line-height: 1.4;
}

.benefits-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-top: 20px;
}

.benefit-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  transition: box-shadow 0.3s ease;
}

.benefit-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.benefit-image {
  width: 100%;
  height: 100px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.benefit-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 10px;
}

.benefit-content {
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.benefit-content h4 {
  margin: 0 0 10px 0;
  color: #333;
}

.benefit-description {
  color: #666;
  margin-bottom: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.5;
}

.benefit-actions {
  margin-top: auto;
  display: flex;
  gap: 10px;
}

.benefit-link {
  display: inline-block;
  padding: 8px 16px;
  background: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  text-align: center;
}

.benefit-link:hover {
  background: #0056b3;
  color: white;
  text-decoration: none;
}

.img-preview {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
  display: flex;
  justify-content: center;
}

.img-preview img {
  max-width: 100px;
  height: auto;
  object-fit: contain;
}


.radio-group,
.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.radio-option,
.checkbox-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.radio-option input,
.checkbox-option input {
  margin: 0;
}

.radio-option label,
.checkbox-option label {
  margin: 0;
  cursor: pointer;
}

.form-control.error {
  border-color: #dc3545;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}