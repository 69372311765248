/* Styles for the create account container */
.create-account {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

/* Styles for the top buttons container */
.create-account .top-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

/* Styles for the heading */
.create-account h2 {
  text-align: center;
  margin-bottom: 20px;
}

/* Styles for form groups */
.create-account .form-group {
  margin-bottom: 15px;
}

/* Styles for form controls (input fields) */
.create-account .form-control {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

/* Styles for alert messages */
.create-account .alert {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
}

/* Styles for success alert messages */
.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

/* Styles for danger alert messages */
.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Styles for buttons */
.create-account .btn {
  width: 100%;
}