/* Styles for the manage questions container */
.manage-questions {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Styles for the top buttons container */
.manage-questions .top-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Styles for the main heading */
.manage-questions h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8em;
  color: #333;
}

/* Styles for form groups */
.manage-questions .form-group {
  margin-bottom: 20px;
}

/* Styles for form labels */
.manage-questions .form-group label {
  font-size: 1.1em;
  color: #333;
  font-weight: 600;
}

/* Styles for form controls (input fields) */
.manage-questions .form-control {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Styles for form buttons container */
.manage-questions .form-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

/* Styles for buttons */
.manage-questions .btn {
  margin-top: 10px;
}

/* Styles for the question list */
.manage-questions .question-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styles for list items in the question list */
.manage-questions .question-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  width: 100%;
  max-width: 600px;
}

/* Styles for the question buttons container */
.manage-questions .question-buttons {
  display: flex;
  gap: 10px;
}

/* Styles for alert messages */
.manage-questions .alert {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
}

/* Styles for success alert messages */
.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

/* Styles for danger alert messages */
.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.manage-questions .options-container {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 10px;
  background-color: #f9f9f9;
}

.manage-questions .option-row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.manage-questions .option-row .form-control {
  flex: 1;
}

.manage-questions .option-row .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  white-space: nowrap;
}

.manage-questions .table-responsive {
  margin-top: 2rem;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  overflow: hidden;
}

.manage-questions .table {
  margin-bottom: 0;
}

.manage-questions .table th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
}

.manage-questions .table td {
  vertical-align: middle;
}

.manage-questions .table td .btn-sm {
  margin-right: 5px;
}

.manage-questions .form-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.question-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}