/* Styles for the user dashboard container */
.user-dashboard {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Styles for the top buttons container */
.user-dashboard .top-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Styles for the main heading */
.user-dashboard h2 {
  margin-bottom: 20px;
  font-size: 1.8em;
  color: #333;
}

/* Styles for the links container */
.user-dashboard .links {
  margin-top: 20px;
}

/* Styles for buttons */
.user-dashboard .btn {
  margin: 10px;
}