@import url('https://fonts.googleapis.com/css2?family=League+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.home-page .main-title {
  font-family: 'League Gothic', sans-serif;
  color: #FFA726;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2.5em;
  letter-spacing: 0.5px;
}

.home-page .subtitle {
  font-family: 'Roboto', sans-serif;
  color: #1976D2;
  text-align: center;
  margin-bottom: 30px;
  font-size: 1.4em;
  font-weight: 500;
  line-height: 1.4;
}

.completion-notice {
  background-color: #E3F2FD;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.completion-notice p {
  color: #1976D2;
  margin: 0;
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.input-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.helper-text {
  color: #666;
  font-size: 0.85em;
  margin-top: 5px;
  display: block;
  font-family: 'Roboto', sans-serif;
}

/* Styles for the home page container */
.home-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Styles for the main heading */
.home-page h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8em;
  color: #333;
}

/* Styles for secondary headings */
.home-page h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8em;
  color: #333;
}

/* Styles for the links container */
.home-page .links {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* Styles for buttons in the links container */
.home-page .links .btn {
  margin: 0 10px;
}

/* Styles for form groups */
.home-page .form-group {
  margin-bottom: 15px;
}

/* Styles for form controls (input fields) */
.home-page .form-control {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Styles for buttons */
.home-page .btn {
  margin-top: 10px;
}

/* Styles for the button group container */
.home-page .button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Styles for the eligibility results container */
.home-page .eligibility-results {
  margin-top: 20px;
}

/* Styles for the eligibility results heading */
.home-page .eligibility-results h3 {
  text-align: center;
}

/* Styles for the eligibility results list */
.home-page .eligibility-results ul {
  list-style-type: none;
  padding: 0;
}

/* Styles for the eligibility results list items */
.home-page .eligibility-results ul li {
  padding: 10px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

/* Styles for the benefits dropdown list */
.home-page .benefits-dropdown ul {
  list-style-type: none;
  padding: 0;
}

/* Styles for the benefits dropdown list items */
.home-page .benefits-dropdown ul li {
  padding: 10px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

/* Styles for the divider */
.home-page .divider {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #ccc;
}

/* Styles for alert messages */
.alert {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
}

/* Styles for success alert messages */
.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

/* Styles for danger alert messages */
.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.benefits-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  margin-top: 20px;
}

.benefit-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  transition: box-shadow 0.3s ease;
}

.benefit-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.benefit-image {
  width: 100%;
  height: 100px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.benefit-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 10px;
}

.benefit-content {
  padding: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.benefit-content h4 {
  margin: 0 0 10px 0;
  color: #333;
}

.benefit-description {
  color: #666;
  margin-bottom: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.5;
}

.benefit-actions {
  margin-top: auto;
  display: flex;
  gap: 10px;
}

.benefit-link {
  display: inline-block;
  padding: 8px 16px;
  background: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  text-align: center;
}

.benefit-link:hover {
  background: #0056b3;
  color: white;
  text-decoration: none;
}

.img-preview {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #f9f9f9;
  display: flex;
  justify-content: center;
}

.img-preview img {
  max-width: 100px;
  height: auto;
  object-fit: contain;
}