.manage-benefits {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Sections */
.section {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.section h3 {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #f0f0f0;
  color: #333;
}

/* Forms */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.form-group label::after {
  content: " *";
  color: #dc3545;
}

.form-control {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

.form-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

/* Image Preview */
.image-preview {
  margin-top: 10px;
  padding: 10px;
  border: 1px dashed #ddd;
  border-radius: 4px;
  text-align: center;
}

.image-preview img {
  max-width: 200px;
  max-height: 100px;
  object-fit: contain;
}

/* Requirements Section */
.requirements-section {
  margin-top: 30px;
}

.requirement-form {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.conditions-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #dee2e6;
}

/* Conditions */
.condition-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  margin-bottom: 10px;
}

.condition-actions {
  display: flex;
  gap: 8px;
}

/* Requirements Display */
.requirement-item {
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 15px;
}

.requirement-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.requirement-type {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
}

.requirement-type.general {
  background: #e3f2fd;
  color: #0d47a1;
}

.requirement-type.necessary {
  background: #fce4ec;
  color: #c2185b;
}

.requirement-type.invalid {
  background: #ffebee;
  color: #b71c1c;
}

.requirement-type.general_necessary {
  background: #f3e5f5;
  color: #7b1fa2;
}

.requirement-conditions {
  padding: 10px;
  background: #f8f9fa;
  border-radius: 4px;
  margin-bottom: 10px;
}

.condition-display {
  padding: 5px 0;
  border-bottom: 1px dashed #dee2e6;
}

.condition-display:last-child {
  border-bottom: none;
}

/* Benefits Grid */
.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.benefit-card {
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.benefit-card:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.benefit-image {
  height: 120px;
  background: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.benefit-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.benefit-content {
  padding: 15px;
}

.benefit-scope {
  color: #666;
  font-size: 0.875rem;
  margin-bottom: 10px;
}

.benefit-description {
  font-size: 0.875rem;
  margin-bottom: 15px;
  line-height: 1.5;
}

.requirements-summary {
  font-size: 0.875rem;
  margin-bottom: 15px;
}

.requirement-summary {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.benefit-actions {
  display: flex;
  gap: 8px;
  margin-top: auto;
}

/* Buttons */
.btn {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
}

.btn-sm {
  padding: 4px 8px;
  font-size: 0.8125rem;
}

.btn-primary {
  background: #007bff;
  color: white;
}

.btn-primary:hover {
  background: #0056b3;
}

.btn-secondary {
  background: #6c757d;
  color: white;
}

.btn-secondary:hover {
  background: #545b62;
}

.btn-danger {
  background: #dc3545;
  color: white;
}

.btn-danger:hover {
  background: #c82333;
}

.form-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

/* Alerts */
.alert {
  padding: 12px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.alert-success {
  background: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-danger {
  background: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}